  form > div {
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    justify-content: center;
    /* flex-wrap: wrap; */
    gap: 100px;
    margin-top: 4%;
  }

  table {
    width: 50%; /* Make the table take up full available width */
    margin: 0 auto; /* Center the table horizontally */
  }

  tr {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  td {
    /* width: 50%; Each cell (label + input) takes 50% width */
    padding: 5px;
  }

  label {
    padding-right: 5px;
  }

  button {
    margin-top: 40px;
  }

  form > div > div {
    display: flex;
    flex-direction: column;
  }