body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #F1FAEE; /* Fondo azul claro */
}

.App {
  text-align: center;
}

input[type="date"] {
  padding: 8px;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

