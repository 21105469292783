.input {
    display: flex;
}

.input > label {
    width: 65px;
}

.input > div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}