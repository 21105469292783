form {
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'space-between';
    align-items: 'center';
    gap: '20px';
    margin-top: '20px';
}

button {
    background-color: '#457B9D';
    color: '#FFFFFF';
    padding: '10px 20px';
    border: 'none';
    border-radius: '5px';
    cursor: 'pointer';
}