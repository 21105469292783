 
.room-card {
    display: flex;
    flex-direction: row;
}

.room-info {
    text-align: left; 
  }
  
.room-image {
  margin-right: 20px; /* Add some spacing between image and text */
}

img {
    width: 300px;
    height: auto;
    
}